export enum LocalStorageEnum {
    token = '_t',
    user_id = '_u',
    languege = '_lang',
    force_change_password = '_fcp',
    user_role = '_ur',
    user_permissions = '_up',
    org_permissions = '_orgp',
    refresh_token = '_rft',
    menuExtended = '_mned',
}
