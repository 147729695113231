import { EventEmitter, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { RolePermissionEnum } from '../models/enums/role-permission.enum';
import { Role } from '../models/role';
import { User } from '../models/user';
import { UserToken } from '../models/user-token';
import { LocalStorageService } from './local-storage.service';
import { RequestService } from './request.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  orgLogoEmitter = new EventEmitter<string>();
  authChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this._isAuth);
  title: string = '';
  isAuth: boolean = this._isAuth;
  permissions: string[] = this._permissions;
  orgPermissions: string[] = this._orgPermissions;
  userId: string = this._userId;
  forceChangePassword: boolean = this._forceChangePassword;

  private refreshTokenPath = '/auth/user/renew-token';
  constructor(
    private router: Router,
    private requestService: RequestService,
    private localStorageService: LocalStorageService
  ) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.isAuth != this._isAuth) {
            this.markStatusChange()
          }
        }
      });
  }

  mockupLogin(data: { username: string, password: string }) {
    this.localStorageService.set(LocalStorageEnum.token, "fake_token");
    if (data.username == 'admin' && data.password == '$Insura11') {
      this.localStorageService.setArray(LocalStorageEnum.user_permissions, [RolePermissionEnum.p_admin]);
    } else if (data.username == 'user' && data.password == '$Insura22') {
      this.localStorageService.setArray(LocalStorageEnum.user_permissions, [RolePermissionEnum.p_user]);
    } else {
      return throwError(() => new Error());
    }

    return of(data);
  }

  login(data: { username: string, password: string }) {
    return this.requestService.postJSON<{ user: User } & UserToken>('/auth/user/login', { data, is_loading: true }).pipe(
      map(res => {
        let role: Role[] = res?.user?.roles as Role[];
        this.localStorageService.set(LocalStorageEnum.token, res.token);
        if(res?.org_permissions) this.localStorageService.setArray(LocalStorageEnum.org_permissions, res.org_permissions);
        this.localStorageService.set(LocalStorageEnum.refresh_token, res.refresh_token);
        if(role && Array.isArray(role) && role?.length > 0) this.localStorageService.setArray(LocalStorageEnum.user_permissions, (role as Role[]).map(r => r.permissions).flat());
        this.localStorageService.set(LocalStorageEnum.user_id, res.user._id!);
        this.localStorageService.set(LocalStorageEnum.force_change_password, !!res.user.force_change_password ? '1' : '');
        this.markForceChangePasswordChange()
        this.markStatusChange()
        return res;
      })
    );
  }

  refreshToken() {
    return this.requestService.postJSON<UserToken>(this.refreshTokenPath, {})
  }

  isRefreshTokenUrl(url: string) {
    return url == this.requestService.getUrl(this.refreshTokenPath)
  }

  logout() {
    this.localStorageService.delete(LocalStorageEnum.token);
    this.localStorageService.delete(LocalStorageEnum.refresh_token);
    this.localStorageService.delete(LocalStorageEnum.user_permissions);
    this.localStorageService.delete(LocalStorageEnum.org_permissions);
    this.localStorageService.delete(LocalStorageEnum.user_id);
    this.localStorageService.delete(LocalStorageEnum.force_change_password);
    this.markStatusChange();
    this.router.navigateByUrl('/auth/login')
  }

  getProfile() {
    return this.requestService.getJSON<User>('/auth/user/info').pipe(
      map(user => {
        this.localStorageService.setArray(LocalStorageEnum.user_permissions, (user.roles as Role[]).map(r => r.permissions).flat());
        this.localStorageService.set(LocalStorageEnum.force_change_password, !!user.force_change_password ? '1' : '');
        this.markForceChangePasswordChange()
        this.markStatusChange()
        return user;
      })
    )
  }

  changePassowrd(old_password: string, new_password: string) {
    return this.requestService.postJSON('/auth/user/change-password', {
      data: { password: old_password, new_password },
      is_alert_error: true,
      is_loading: true
    }).pipe(
      map(res => {
        this.localStorageService.set(LocalStorageEnum.force_change_password, '');
        this.markForceChangePasswordChange()
        return res;
      })
    )
  }

  getProfilePicture() {
    return this.requestService.getBlob(`/api/employee/profile`);
  }
  getUserProfilePicture(id: string) {
    return this.requestService.getBlob(`/api/employee/profile/user/${id}`);
  }

  private markForceChangePasswordChange() {
    this.forceChangePassword = this._forceChangePassword;
    if (this.forceChangePassword) {
      this.router.navigateByUrl('/auth/change-password')
    }
  }

  private markStatusChange() {
    this.isAuth = this._isAuth;
    this.permissions = this._permissions;
    this.orgPermissions = this._orgPermissions;
    this.userId = this._userId;
    this.forceChangePassword = this._forceChangePassword;
    this.authChange$.next(this._isAuth);
  }

  private get _isAuth(): boolean {
    return this.localStorageService.get(LocalStorageEnum.token) ? true : false;
  }

  private get _permissions(): string[] {
    return this.localStorageService.getArray(LocalStorageEnum.user_permissions);
  }

  private get _orgPermissions(): string[] {
    return this.localStorageService.getArray(LocalStorageEnum.org_permissions);
  }

  private get _userId(): string {
    return this.localStorageService.get(LocalStorageEnum.user_id);
  }

  private get _forceChangePassword(): boolean {
    return !!this.localStorageService.get(LocalStorageEnum.force_change_password);
  }
}
